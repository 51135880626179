import { TPermissions } from "@src/api/auth/hook";
import { createContext } from "react";


export type TUser = {
  first_name: string;
  last_name: string;
  orgName: string;
  avatar: string;
  user_id: string,
  organization_id: string,
  created_at: string,
  enable_production: boolean,
  permissions: TPermissions,
  email: string,
  is_client: boolean,
  is_active: boolean
}

export type TAuthContext = {
  isAuthenticated: boolean;
  setAuthenticated: (isAuthenticated: boolean) => void;
  user: TUser | null;
  setUser: (user: TUser | null) => void;
  isLoading: boolean;
}

const defaultChatContext: TAuthContext = {
  isAuthenticated: false,
  setAuthenticated: () => { },
  user: null,
  setUser: () => { },
  isLoading: true,
}

export const AuthContext = createContext<TAuthContext>(defaultChatContext);


export const AuthContextProvider = AuthContext.Provider
