import { Grid } from "@mui/material";
import { useLogin, useResetPassword } from "@src/api/auth/hook";
import { AppAlertsCtx } from "@src/components/AppAlerts";
import { AuthContext } from "@src/components/auth/auth.ctx";
import { EEvent, useTrackEvents } from "@src/instrumentation/useTrackEvents";
import { ForgotPasswordDialog } from "@src/pages/auth/components/ForgotPasswordDialog";
import { Header } from "@src/pages/auth/components/Header";
import LoginForm from "@src/pages/auth/components/LoginForm";
import SDRDescription from "@src/pages/auth/components/SDRDescription";
import { rootPath } from "@src/utils/spa.url";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";



export function Login() {
  const { addAlert } = useContext(AppAlertsCtx);
  const { setAuthenticated, setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const { mutateAsync: mutateAsyncLogin, isPending: isLoginPending } = useLogin();
  const { mutateAsync: mutateAsyncResetPassword, isPending: isResetPasswordPending } = useResetPassword();

  const { identify, trackEvent } = useTrackEvents();

  const [open, setOpen] = useState(false);


  const handleSubmit = async (email: string, password: string) => {

    try {
      const response = await mutateAsyncLogin({ email, password });
      if (response.is_active) {
        setAuthenticated(true);
        setUser({
          email: response.email,
          orgName: response.organization.organization_name,
          avatar: '',
          created_at: response.created_at,
          user_id: response.user_id,
          first_name: response.first_name,
          last_name: response.last_name,
          organization_id: response.organization.organization_id,
          enable_production: response.enable_production,
          permissions: response.permissions,
          is_client: response.organization.is_client,
          is_active: response.is_active
        });
        identify(response.user_id, {
          email: response.email,
          first_name: response.first_name,
          last_name: response.last_name,
          org_name: response.organization.organization_name
        })
        trackEvent(EEvent.USER_SIGNED_IN, {
          email: response.email,
          first_name: response.first_name,
          last_name: response.last_name,
          org_name: response.organization.organization_name
        })
        navigate(rootPath);
        return;
      }
    }
    catch (error: any) {
      addAlert({ type: 'error', message: error?.message || 'Invalid email or password' });
    }
  };

  const handleEmailSubmit = async (email: string) => {
    try {
      const response = await mutateAsyncResetPassword({ email });
      addAlert({ type: 'success', message: response.message });
      setOpen(false);
      trackEvent(EEvent.USER_PASSWORD_RESET_REQUESTED, { email });
    }
    catch (error: any) {
      addAlert({ type: 'error', message: error?.message || 'Failed to send password reset email' });
    }
  }

  return (
    <>
      <Header />
      <Grid container justifyContent="center" alignItems="center" sx={{ height: '100vh' }} paddingX={4} spacing={6}>
        <Grid item xs={12} md={6} lg={5} xl={4} order={[2, 2, 1]}>
          <SDRDescription />
        </Grid>
        <Grid item xs={12} md={6} lg={5} xl={4} order={[1, 1, 2]}>
          <LoginForm onSubmit={handleSubmit} onForgotPassword={() => setOpen(true)} isLoading={isLoginPending} />
        </Grid>
      </Grid>
      <ForgotPasswordDialog open={open} onClose={() => setOpen(false)} onEmailSubmit={handleEmailSubmit} isLoading={isResetPasswordPending} />
    </>
  );
}
