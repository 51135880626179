import { API_BASE_URL } from "@src/utils/env.values";
import http from "@src/api/http";
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { AuthContext, TUser } from "@src/components/auth/auth.ctx";

type TOrganization = {
  organization_id: string;
  organization_name: string;
  max_users: number;
  is_client: boolean;
}

export type TPermissions = {
  add_leads: boolean;
  change_organization: boolean;
  list_organizations: boolean;
  is_admin: boolean;
}

export type TCommonAuthUserResponse = {
  user_id: string;
  is_active: boolean;
  email: string;
  organization: TOrganization;
  enable_production: boolean;
  created_at: string,
  first_name: string,
  last_name: string,
  permissions: TPermissions
}

const userBaseURL = `${API_BASE_URL}/users`;

export type OrgUser = {
  email: string;
  first_name: string;
  id: string;
  last_name: string;
}

type GetUsersResponse = {
  users: OrgUser[];
}

const getUsersQueryKey = (user: TUser | null) => ['users', user?.organization_id].filter(Boolean);

export const useGetUsers = () => {
  const { user } = useContext(AuthContext);
  return useQuery<GetUsersResponse>({
    queryKey: getUsersQueryKey(user),
    queryFn: () => http.get(userBaseURL),
    enabled: !!user && user.permissions.is_admin,
  })
}

type TLoginArgs = {
  email: string;
  password: string;
}

type TLoginResponse = TCommonAuthUserResponse;

export function useLogin(options?: UseMutationOptions<TLoginResponse, Error, TLoginArgs>) {
  return useMutation<TLoginResponse, Error, TLoginArgs>({
    mutationFn: (creds: TLoginArgs) => http.post(`${userBaseURL}/login`, creds),
    ...options
  })
}

const userDetailsQueryKey = 'userDetails';

export function useLogoutMutation(options?: UseMutationOptions<void, Error, void>) {
  const { onSuccess, ...restOptions } = options || {};
  const queryClient = useQueryClient();
  return useMutation<void, Error, void>({
    mutationFn: () => http.get(`${userBaseURL}/logout`),
    onSuccess: async (data, variables, context) => {
      await onSuccess?.(data, variables, context);
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] !== userDetailsQueryKey
      });
    },
    ...restOptions
  });
}

type TUserDetailsResponse = TCommonAuthUserResponse;

export const getUserDetailsQueryKey = () => [userDetailsQueryKey];

export function useUserDetails(options?: Partial<UseQueryOptions<TUserDetailsResponse>>) {

  return useQuery<TUserDetailsResponse>({
    queryKey: getUserDetailsQueryKey(),
    queryFn: () => http.get(`${userBaseURL}/me`),
    retry: 0,
    throwOnError: (error, query) => {
      if (query.queryKey[0] === userDetailsQueryKey) {
        return false;
      }
      return true;
    },
    ...options
  })
}



type UserSettings = {
  end_hour: string;
  start_hour: string;
  time_zone: string;
  deal_size?: string;
}

type UserSettingsResponse = {
  settings: UserSettings
}

const getUserSettingsQueryKey = (user: TUser | null) => ["userSettings", user?.user_id]

export function useGetUserSettings(options?: UseQueryOptions<UserSettingsResponse>) {
  const { user } = useContext(AuthContext)
  return useQuery<UserSettingsResponse>({
    queryKey: getUserSettingsQueryKey(user),
    queryFn: () => http.get(`${userBaseURL}/me/user_settings`),
    enabled: !!user,
    ...options
  })
}

type UpdateUserSettingsArgs = {
  setting_name: string;
  setting_value: string;
}

type UpdateUserSettingsResponse = {
  message: string
}

export function useUpdateUserSettings(options?: UseMutationOptions<UpdateUserSettingsResponse, Error, UpdateUserSettingsArgs[]>) {
  const { user } = useContext(AuthContext)
  const queryClient = useQueryClient()
  return useMutation<UpdateUserSettingsResponse, Error, UpdateUserSettingsArgs[]>({
    mutationFn: (args: UpdateUserSettingsArgs[]) => http.patch(`${userBaseURL}/me/user_settings`, args),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: getUserSettingsQueryKey(user) })
    },
    ...options
  })
}



// Signup Hook
const signupURL = `${API_BASE_URL}/signup`;

type TSignupArgs = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

type TSignupResponse = {
  valid: boolean;
  user: string;
}

export function useSignup(options?: UseMutationOptions<TSignupResponse, Error, TSignupArgs>) {
  return useMutation<TSignupResponse, Error, TSignupArgs>({
    mutationFn: (data: TSignupArgs) => http.post(signupURL, data),
    ...options
  })
}

const confirmEmailURL = `${API_BASE_URL}/confirm-email`;

type TConfirmEmailArgs = {
  token: string;
}

type TConfirmEmailResponse = {
  type: 'success' | 'error';
  message: string;
}

export function useConfirmEmail(options?: UseMutationOptions<TConfirmEmailResponse, Error, TConfirmEmailArgs>) {
  return useMutation<TConfirmEmailResponse, Error, TConfirmEmailArgs>({
    mutationFn: (data: TConfirmEmailArgs) => http.post(confirmEmailURL, data),
    ...options
  })
}


const resetPasswordURL = `${API_BASE_URL}/reset_password`;

type TResetPasswordArgs = {
  email: string;
}

type TResetPasswordResponse = {
  message: string;
}

export function useResetPassword(options?: UseMutationOptions<TResetPasswordResponse, Omit<Error, 'type'>, TResetPasswordArgs>) {
  return useMutation<TResetPasswordResponse, Omit<Error, 'type'>, TResetPasswordArgs>({
    mutationFn: (data: TResetPasswordArgs) => http.post(resetPasswordURL, data),
    ...options
  })
}

const changePasswordURL = `${API_BASE_URL}/change_password`;

type TChangePasswordArgs = {
  token: string;
  new_password: string;
}

type TChangePasswordResponse = {
  message: string;
}

export function useChangePassword(options?: UseMutationOptions<TChangePasswordResponse, Omit<Error, 'type'>, TChangePasswordArgs>) {
  return useMutation<TChangePasswordResponse, Omit<Error, 'type'>, TChangePasswordArgs>({
    mutationFn: (data: TChangePasswordArgs) => http.post(changePasswordURL, data),
    ...options
  })
}
