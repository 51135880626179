import { Box, BoxProps } from "@mui/material";

const VerticalCenter = ({ children, justifyContent = "center", height = "100%", ...props }: BoxProps) => {
  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={justifyContent} height={height} {...props}>
      {children}
    </Box>
  )
}

export default VerticalCenter;
