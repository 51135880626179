import ComponentSpinner from "@src/components/ComponentSpinner";
import { AuthContext } from "@src/components/auth/auth.ctx";
import { leads } from "@src/utils/spa.url";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * This component would also be useful in case of a callback based OAuth flow
 * For now it just redirects to the chat page as AuthProvider takes care
 * of handling the authentication
 */
export function Root() {
  const { isLoading, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate(leads.base, { replace: true });
      return;
    }
  }, [isAuthenticated, isLoading, navigate])

  return <ComponentSpinner />;


}
