import { AccountBoxOutlined, CableOutlined, HelpCenterOutlined, Logout } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import UserAvatar from '@src/components/UserAvatar';
import { AuthContext } from "@src/components/auth/auth.ctx";
import { useHubSpotChat } from '@src/components/hubspot/useHubSpotChat';
import { auth, integrations } from "@src/utils/spa.url";
import { NestedMenuItem } from 'mui-nested-menu';
import { useContext, useState } from 'react';
import { Link } from "react-router-dom";

export default function UserSettings() {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [mainAnchorEl, setMainAnchorEl] = useState<null | HTMLElement>(null);
  const isMainMenuOpen = Boolean(mainAnchorEl);

  const { isReady, openHandler } = useHubSpotChat()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setMainAnchorEl(event.currentTarget);
  };

  const handleMainMenuClose = () => {
    setMainAnchorEl(null);
  };

  if (!isAuthenticated || !user) {
    return null;
  }


  return (
    <>
      <IconButton onClick={handleClick} size='large'>
        <UserAvatar name={user.first_name} />
      </IconButton>
      <Menu
        anchorEl={mainAnchorEl}
        open={isMainMenuOpen}
        onClose={handleMainMenuClose}
        MenuListProps={{ dense: true, sx: { minWidth: 200 } }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <NestedMenuItem
          parentMenuOpen={isMainMenuOpen}
          leftIcon={(
            <ListItemIcon sx={{ marginLeft: 1.5 }}>
              <AccountBoxOutlined fontSize="small" />
            </ListItemIcon>
          )}
          disabled={true}
          rightIcon={null}
          sx={{ opacity: '1 !important' }}
          renderLabel={() => (
            <ListItemText primary={`${user.first_name} ${user.last_name}`} secondary={user.orgName} />
          )}>
        </NestedMenuItem>

        <Divider />

        <MenuItem component={Link} to={integrations.base} onClick={() => handleMainMenuClose()}>
          <ListItemIcon>
            <CableOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Settings
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => { openHandler(); handleMainMenuClose() }} disabled={!isReady}>
          <ListItemIcon>
            <HelpCenterOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Support
          </ListItemText>
        </MenuItem>

        <Divider sx={{ margin: `0 !important` }} />
        <MenuItem component={Link} to={auth.logout} onClick={handleMainMenuClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Logout
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}
