import { Grid } from "@mui/material";
import ComponentSpinner from "@src/components/ComponentSpinner";

export default function PageSpinner() {
  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item>
        <ComponentSpinner />
      </Grid>
    </Grid>
  );
}

