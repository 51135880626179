import { useCallback, useEffect, useRef, useState } from "react";

type hsConversationsSettings = {
  loadImmediately?: boolean;
  inlineEmbedSelector?: string;
  enableWidgetCookieBanner?: boolean;
  disableAttachment?: boolean;
}

declare global {
  var hsConversationsSettings: hsConversationsSettings; // eslint-disable-line no-var
  var HubSpotConversations: any; // eslint-disable-line no-var
  var hsConversationsOnReady: any; // eslint-disable-line no-var
}

//* Change if changed in `index.html`
const scriptId = 'hs-script-loader';

export const useHubSpotChat = () => {
  const [hubSpotAPIReady] = useState(() => {
    const script = document.getElementById(scriptId) as HTMLScriptElement;
    return !!script;
  });
  const [isChatLoaded, setIsChatLoaded] = useState(false);
  const closeHandlerRef = useRef<(event: any) => void>((event) => {
    setIsChatLoaded(false);
    window.HubSpotConversations.widget.remove();
  });

  useEffect(() => {
    if (!hubSpotAPIReady) return
    window.HubSpotConversations.on('widgetClosed', closeHandlerRef.current)
    return () => {
      window.HubSpotConversations.off('widgetClosed', closeHandlerRef.current)
      window.HubSpotConversations.widget.remove();
    }
  }, [hubSpotAPIReady])

  const openHandler = useCallback(() => {
    if (isChatLoaded) {
      window.HubSpotConversations.widget.open();
      return;
    }

    window.HubSpotConversations.widget.load();
    window.HubSpotConversations.widget.open();
    setIsChatLoaded(true);

  }, [isChatLoaded]);

  return {
    isReady: hubSpotAPIReady,
    openHandler,
  };
}
