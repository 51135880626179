import { Stack, styled } from "@mui/material";
import { AnnouncementRibbon } from "@src/components/AnnouncementRibbon";


const StyledStack = styled(Stack)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  left: 0,
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  zIndex: 1,
}))

// Use this component to add header elements that are common to all pages
export default function AppHeader() {
  return (
    <StyledStack>
      <AnnouncementRibbon />
    </StyledStack>
  );
}
