import { createTheme, ThemeOptions, inputBaseClasses } from "@mui/material";

declare module '@mui/material/styles' {

  interface Palette {
    btnAction: Palette['primary'];
    tertiary: Palette['primary'];
    dark: Palette['primary'];
  }

  interface PaletteOptions {
    btnAction?: PaletteOptions['primary'];
    tertiary?: PaletteOptions['primary'];
    dark?: PaletteOptions['primary'];
  }

  interface Theme {
    custom: {
      sidebarWidth: string;
      feedbackIconSize: number;
    },
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      sidebarWidth?: string;
      feedbackIconSize?: number;
    }
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    btnAction: true;
    tertiary: true;
  }
  interface ButtonPropsSizeOverrides {
    xl: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    xsm: true;
  }
}

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsColorOverrides {
    btnAction: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    btnAction: true;
  }
}

declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    btnAction: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    btnAction: true;
    tertiary: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    btnAction: true;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    btnAction: true;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    btnAction: true;
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsColorOverrides {
    btnAction: true;
  }
}

const colors = {
  peachPuff: '#ffcd9e',
  brightGreen: '#aee64b',
  lavender: '#d6e1ff',
  purple: '#d0a8f7',
  whiteSmoke: '#efefef',
  darkGreen: '#254e34',
  aquamarine: '#a8fbd3',
  whiteSmoke2: '#eff3f6',
  linen: '#f7f6f0',
  linenDark: '#efede2',
  black: '#000000',
  white: '#ffffff',
  dark: '#131416',
  instantlyPaper: '#f9f9f9'
}


const commonThemeValues: ThemeOptions = {
  custom: {
    sidebarWidth: '250px',
    feedbackIconSize: 18,
  },
  shape: {
    borderRadius: 6,
  }
}

const defaultTheme = createTheme(commonThemeValues);

const richTextContentClasses = '.MuiTiptap-RichTextContent-root .ProseMirror'
export const smallFontSizeRichEditorClassName = 'small-font-size-editor'

const smallRichTextContentClasses = `.${smallFontSizeRichEditorClassName} ${richTextContentClasses}`

export const lightTheme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
    button: {
      textTransform: 'none',
    },
    h1: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.15rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.10rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: colors.darkGreen,
    },
    secondary: {
      main: colors.brightGreen,
    },
    tertiary: {
      main: colors.aquamarine,
      contrastText: colors.dark,
    },
    success: {
      main: colors.darkGreen,
    },
    info: {
      main: colors.purple,
    },
    background: {
      default: colors.white,
      paper: colors.instantlyPaper,
    },
    text: {
      primary: colors.black,
    },
    btnAction: {
      main: colors.black,
      contrastText: colors.white,
    },
    dark: {
      main: colors.dark,
      contrastText: colors.linen,
    },

  },
  components: {
    // * This is specifically for the rich editor
    MuiCssBaseline: {
      styleOverrides: (theme) => `
        .MuiTiptap-ControlledBubbleMenu-root {
          z-index: ${theme.zIndex.modal + 1} !important;
        }
        .MuiTiptap-HeadingWithAnchorComponent-linkIcon {
          display: none !important;
        }
        ${richTextContentClasses} a {
          color: ${theme.palette.primary.main} !important;
          text-decoration: underline !important;
        }
        ${smallRichTextContentClasses} {
          font-size: 14px !important;
        }
        ${smallRichTextContentClasses} h1, ${smallRichTextContentClasses} h2, ${smallRichTextContentClasses} h3, ${smallRichTextContentClasses} h4 {
          font-size: 16px !important;
        }

      `
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top',
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.black,
          color: colors.white
        },
        arrow: {
          color: colors.black
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.white,
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(2),
          '&:last-child': {
            paddingBottom: defaultTheme.spacing(2),
          }
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { size: 'xl' },
          style: {
            paddingTop: defaultTheme.spacing(2),
            paddingBottom: defaultTheme.spacing(2),
          }
        }
      ]
    },
    MuiTextField: {
      variants: [
        {
          props: { size: 'xsm' },
          style: {
            [`& .${inputBaseClasses.input}`]: {
              padding: `${defaultTheme.spacing(0.5)} ${defaultTheme.spacing(1)}`,
              fontSize: defaultTheme.typography.body2.fontSize,
            },
            [`& .${inputBaseClasses.adornedEnd}`]: {
              paddingRight: defaultTheme.spacing(0.5),
            }
          }
        }
      ]
    }
  },
  ...commonThemeValues,
});

// Removed For now
// export const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//     primary: {
//       main: '#ade64b',
//     },
//     secondary: {
//       main: '#3b82f6'
//     },
//     background: {
//       default: '#0f172a',
//       paper: '#15254a',
//     },
//   },
//   ...commonThemeValues,
// });


