import { Avatar } from "@mui/material";

const size = 32;

export default function UserAvatar({ name, showAvatar = true }: { name: string, showAvatar?: boolean }) {

  return (
    <Avatar variant="rounded" sx={{ backgroundColor: "#0000009c", width: size, height: size, visibility: showAvatar ? 'visible' : 'hidden' }}>
      {name[0].toUpperCase() || 'U'}
    </Avatar>
  )
}
