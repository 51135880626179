import { TCommonAuthUserResponse, useUserDetails } from '@src/api/auth/hook';
import PageSpinner from '@src/components/PageSpinner';
import { AuthContextProvider, TAuthContext, TUser } from '@src/components/auth/auth.ctx';
import { EEvent, useTrackEvents } from '@src/instrumentation/useTrackEvents';
import { deepComparison } from '@src/utils/common';
import { auth, error, integrations, unsubscribe } from '@src/utils/spa.url';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

const ignoredPaths = [
  auth.signIn,
  auth.signup,
  auth.pendingVerification,
  auth.confirmEmail,
  auth.resetPassword,
  integrations.salesforceCallback,
  integrations.hubspotCallback,
  unsubscribe.base,
  error.base
];

function convertFetchedUserToUser(fetchedUser: TCommonAuthUserResponse) {
  // convert fetchedUser to TUser for JSON.stringify comparison
  const fetchedUserConverted: TUser = {
    avatar: '',
    created_at: fetchedUser.created_at,
    email: fetchedUser.email,
    first_name: fetchedUser.first_name,
    last_name: fetchedUser.last_name,
    orgName: fetchedUser.organization.organization_name,
    enable_production: fetchedUser.enable_production,
    permissions: fetchedUser.permissions,
    is_client: fetchedUser.organization.is_client,
    is_active: fetchedUser.is_active,
    organization_id: fetchedUser.organization.organization_id,
    user_id: fetchedUser.user_id
  };
  return fetchedUserConverted;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState<TUser | null>(null);
  const navigate = useNavigate();
  const { pathname, state, search } = useLocation();
  const { resetIdentity, trackEvent } = useTrackEvents();
  // when we hard refresh, the context is lost, and we need to refetch the user details only on private routes
  // refetching on public routes is pointless as we already have the user details in the context
  const shouldFetchUserDetails = !ignoredPaths.some(path => pathname.includes(path));
  const { data, isLoading, isError, dataUpdatedAt } = useUserDetails({ enabled: shouldFetchUserDetails });

  const authCtx: TAuthContext = useMemo(() => ({
    isAuthenticated,
    setAuthenticated,
    user,
    setUser,
    isLoading
  }), [isAuthenticated, isLoading, user]);


  // util function to set user details
  const setUserDetails = useCallback((userData: typeof data) => {
    if (!userData) return;
    setUser({
      email: userData.email, orgName: userData.organization.organization_name,
      avatar: '',
      created_at: userData.created_at,
      user_id: userData.user_id,
      first_name: userData.first_name,
      last_name: userData.last_name,
      organization_id: userData.organization.organization_id,
      enable_production: userData.enable_production,
      permissions: userData.permissions,
      is_client: userData.organization.is_client,
      is_active: userData.is_active
    });
  }, [setUser]);

  const resetUser = useCallback(() => {
    setAuthenticated(false);
    setUser(null);
    trackEvent(EEvent.USER_SIGNED_OUT);
    resetIdentity();
  }, [setAuthenticated, setUser, trackEvent, resetIdentity]);

  useEffect(() => {
    if (isError) {
      resetUser();
      navigate(auth.signIn);
    }
  }, [isError, navigate, resetUser])

  useEffect(() => {
    if (!dataUpdatedAt) return;
    if (isAuthenticated && user && data) {
      const fetchedUser = convertFetchedUserToUser(data);
      const isSameUserDetails = deepComparison(user, fetchedUser);
      if (isSameUserDetails) return;
      if (!fetchedUser.is_active) {
        resetUser();
        navigate(auth.signIn);
        return;
      }
      setUserDetails(data);
    }
  }, [data, dataUpdatedAt, isAuthenticated, user, setUserDetails, navigate, resetUser])

  useEffect(() => {
    // TODO: we should be able to remove this from here and add the logic to the PrivateRoute. Need to verify that the logic works
    if (isLoading) return;
    if (!isAuthenticated) {

      if (isError && !data) {
        resetUser();
        if (ignoredPaths.some(path => pathname.includes(path))) {
          return;
        }
        else {
          navigate(auth.signIn);
        }
        return;
      }
      if (!isError && data) {
        setAuthenticated(true);
        setUserDetails(data);
        navigate(`${pathname}${search}`, { replace: true });
        return
      }
    }
  }, [data, isAuthenticated, isError, isLoading, navigate, pathname, search, setUserDetails, resetUser])

  if (isLoading) {
    return <PageSpinner />;
  }


  return (
    <AuthContextProvider value={authCtx}>
      {children}
    </AuthContextProvider>
  );
}
