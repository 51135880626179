import { Box, Grid } from "@mui/material";
import AppHeader from "@src/components/AppHeader";
import AppSidebar from "@src/components/AppSidebar";
import { AppContextProvider } from "@src/layout/app.context";
import { Outlet } from "react-router-dom";

export const contentId = "main-content";

export function AppLayout() {

  return (
    <AppContextProvider value={{}}>
      <Grid container height="100vh" wrap="nowrap">
        <Grid item>
          <AppSidebar />
        </Grid>
        <Grid
          id={contentId}
          flex={1}
          item
          minHeight={"100vh"}
          sx={{ overflowY: 'auto' }}
          display='flex'
          flexDirection='column'
        >
          <AppHeader />
          <Box flex={1} paddingX={4} paddingY={3}>
            <Outlet />
          </Box>
        </Grid>
      </Grid>
    </AppContextProvider>
  );
}
