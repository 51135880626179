/**
 * This component is was a placeholder wrapper for OAuth based authentication.
 * Since, we have our own authentication we can just return the outlet.
 * Later on, if we use something like Auth0, we will need to use this component.
 */

import ComponentSpinner from "@src/components/ComponentSpinner";
import { AuthContext } from "@src/components/auth/auth.ctx";
import { useContext } from "react";

export default function PrivateRoute({ outlet }: { outlet: React.ReactNode }) {
  const { isLoading } = useContext(AuthContext);

  if (isLoading) {
    return <ComponentSpinner />;
  }

  // if (!isAuthenticated) {
  //   return <Navigate to={loginPath} replace />;
  // }

  return outlet;
}
