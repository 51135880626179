
interface IFetchParams {
  method: string,
  [k: string]: string | number | undefined | object
}
interface IOptions {
  isBlob: boolean,
  isFile: boolean,
}

const DEFAULT_OPTIONS = {
  isFile: false,
  isBlob: false,
};

const fetchWrapper = async (url: string, { method, ...rest }: IFetchParams, options?: Partial<IOptions>) => {
  const { isBlob, isFile } = { ...DEFAULT_OPTIONS, ...options };
  // TODO: Add after authentication
  // const requestId = uuidv4();
  // const sessionId = retrieve(CacheKeys.sessionId, { setToIfNull: uuidv4() });
  const headers = new Headers();
  headers.set('Accept', 'application/json');
  headers.set('Access-Control-Allow-Credentials', 'true');
  // headers.set('Access-Control-Allow-Origin', '*');

  if (!isFile) {
    headers.set('Content-Type', 'application/json');
  }

  // TODO: Add after authentication
  // headers.set('X-Request-ID', requestId);
  // headers.set('X-Session-ID', sessionId);

  try {
    const response = await fetch(url, {
      method,
      headers,
      redirect: 'error',
      ...rest,
    });

    if (!response.ok) {
      let message = response.statusText;
      if (response.status >= 400 && response.status < 500) {
        // Assumes API standardizes error responses to { message: string }
        const body = await response.json();
        message = body.message || body.error;
      }
      // return {};

      throw new Error(message);
    }
    else if (response.headers.get('content-length') === '0') {
      // Handles HTTP 201, 204 -> no content responses
      return {};
    }
    else { return await response.json(); }
  }
  catch (err) {
    /* Catch network/non-API errors */
    console.error(`Request to ${url} failed`, err);
    throw err;
  }
};

const http = {
  delete: async (url: string, payload?: object) => fetchWrapper(url, { method: 'DELETE', body: JSON.stringify(payload) }),

  get: async (url: string) => fetchWrapper(url, { method: 'GET' }),

  patch: async (url: string, payload: object) => fetchWrapper(
    url,
    { method: 'PATCH', body: JSON.stringify(payload) },
  ),

  post: async (url: string, payload: object) => fetchWrapper(
    url,
    { method: 'POST', body: JSON.stringify(payload) },
  ),

  put: async (url: string, payload: object) => fetchWrapper(
    url,
    { method: 'PUT', body: JSON.stringify(payload) },
  ),

  postFile: async (url: string, payload: FormData) => fetchWrapper(
    url,
    { method: 'POST', body: payload },
    { isFile: true },
  ),


};

export default http;
