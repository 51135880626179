export const rootPath = '/';


export const auth = {
  signIn: '/signin',
  signup: '/signup',
  logout: '/logout',
  confirmEmail: '/confirm-email',
  pendingVerification: '/verification-pending',
  resetPassword: '/reset-password',
}


export const integrations = {
  base: '/integrations',
  salesforceCallback: '/integrations/salesforce/callback',
  hubspotCallback: '/integrations/hubspot/callback',
  gmailCallback: '/integrations/gmail/callback',
}

export const pipeline = {
  base: '/pipeline'
}

export const leads = {
  base: '/leads',
  details: (id: string) => `/leads/${id}`,
  detailsKey: 'id' as const,
  leadSequencePath: '/sequence',
  leadSequence: (id: string) => `/leads/${id}/sequence`
}

export const companies = {
  base: '/companies',
  details: (id: string) => `/companies/${id}`,
  detailsKey: 'id' as const,
}

export const linkedin = {
  base: '/linkedin',
  connectionsBase: 'connections',
  messagesBase: 'messages',
}

export const campaigns = {
  base: '/campaigns',
  details: (id: string) => `/campaigns/${id}`,
  sequenceBase: 'sequences',
  analyticsBase: 'analytics',
  settingsBase: 'settings',
  leadsBase: 'leads',
  detailsKey: 'id' as const
}

export const unibox = {
  base: '/unibox',
}


export const segments = {
  base: '/segments',
}

export const error = {
  base: '/error',
}

export const unsubscribe = {
  base: '/unsubscribe',
}

