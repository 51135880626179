
export const getFirstAndLastName = (name: string) => {
  const names = name.split(' ');
  return {
    firstName: names[0],
    lastName: names.length > 1 ? names[names.length - 1] : ''
  }
}

export const UppercaseLetters: readonly string[] = [
  'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
  'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
];

export function convertTextToHtml(text: string): string {
  // Split the text into paragraphs using new line or escaped new line characters
  const paragraphs = text.split(/\n/);

  // Wrap each paragraph in <p> tags and join them into a single string
  const htmlString = paragraphs.map(paragraph => `<p>${paragraph}</p>`).join('');

  return htmlString;
}


// Function to generate the updated text based on the cursor position
export const getTextBasedOnCursorPosition = (cursorPosition: number | null | undefined, field: string, currentText: string): string => {
  if (cursorPosition !== null && cursorPosition !== undefined) {
    const startText = currentText.slice(0, cursorPosition);
    const endText = currentText.slice(cursorPosition);
    return `${startText}{{${field}}}${endText ? ` ${endText}` : ''}`;
  }
  else {
    return `${currentText} {{${field}}}`;
  }
};

export const getWordCount = (text: string): number => {
  return text.trim().split(/\s+/).filter(word => word.trim() !== '').length;
}

export const getCharacterCount = (text: string): number => {
  return text.length;
}

export const getReadingTimeInSeconds = (text: string): number => {
  const wordCount = getWordCount(text);
  const seconds = (wordCount / 200) * 60;
  return Math.ceil(seconds);
}


export function isValidJson(jsonString: string): boolean {
  if (/^\s*(\{.*\}|\[.*\])\s*$/.test(jsonString)) {
    try {
      JSON.parse(jsonString);
      return true;
    }
    catch (e) {
      return false;
    }
  }
  return false;
}


export function deepComparison (first: any, second: any) {


  /* Checking if the types and values of the two arguments are the same. */
  if(first === second) return true


  /* Checking if any arguments are null */
  if(first === null || second === null) return false

  /* Checking if any argument is none object */
  if(typeof first !== 'object'  || typeof second !== 'object') return false



  /* Using Object.getOwnPropertyNames() method to return the list of the objects’ properties */
  const first_keys = Object.getOwnPropertyNames(first);

  const second_keys = Object.getOwnPropertyNames(second);



  /* Checking if the objects' length are same*/
  if(first_keys.length !== second_keys.length) return false




  /* Iterating through all the properties of the first object with the for of method*/
  for (const key of first_keys) {


      /* Making sure that every property in the first object also exists in second object. */ 
      if(!Object.hasOwn(second, key)) return false


      /* Using the deepComparison function recursively (calling itself) and passing the values of each property into it to check if they are equal. */
      if (deepComparison(first[key], second[key]) === false) return false

  }



  // if no case matches, returning true
  return true;
}

