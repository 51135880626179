import { CheckCircle } from "@mui/icons-material";
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

const ListItemWithIcon = function ({ primary }: { primary: string }) {
  return (
    <ListItem sx={{ paddingX: 0 }}>
      <ListItemIcon sx={{ minWidth: 32 }}>
        <CheckCircle color="primary" />
      </ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  )
}

export default function SDRDescription() {
  return (
    <Box>
      <Typography variant="h1" marginBottom={2}>SDR Co-Pilot</Typography>
      <Typography variant="h2" marginBottom={4}>Focus on closing deals, not grunt work.</Typography>

      <List disablePadding>
        <ListItemWithIcon primary="Find similar leads in a click" />
        <ListItemWithIcon primary="Instantly research leads" />
        <ListItemWithIcon primary="Draft emails in minutes" />
        <ListItemWithIcon primary="Setup automated follow-ups" />
      </List>
    </Box>
  )
}
