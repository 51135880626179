import logo from '@assets/revamp-logo-full.png';
import { Grid, Stack, styled } from "@mui/material";
import { rootPath } from "@src/utils/spa.url";
import Image from "mui-image";
import { Link, Outlet } from "react-router-dom";


const StyledStack = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  backgroundColor: theme.palette.background.default,
  zIndex: 1,
}))

function Header() {
  return (
    <StyledStack direction='row' justifyContent='space-between' alignItems='center' width='100%' padding={2}>
      <Link to={rootPath}><Image src={logo} alt="Revamp Logo" width={80} /></Link>
    </StyledStack>
  )
}


export function CenteredLayout() {

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item sm={10} md={6} lg={4} xl={3}>
        <Header />
        <Outlet />
      </Grid>
    </Grid>
  );
}
