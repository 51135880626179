import { usePostHog } from "posthog-js/react"
import { useCallback } from "react";

export const enum EEvent {
  USER_SIGNED_UP = 'user_signed_up',
  USER_SIGNED_IN = 'user_signed_in',
  USER_SIGNED_OUT = 'user_signed_out',
  USER_EMAIL_VERIFIED = 'user_email_verified',
  USER_EMAIL_VERIFICATION_SENT = 'user_email_verification_sent',
  USER_PASSWORD_RESET_REQUESTED = 'user_password_reset_requested',
  USER_PASSWORD_RESET = 'user_password_reset',

  TRAINING_DATA_ADDED = 'training_data_added',
  TRAINING_DATA_REMOVED = 'training_data_removed',
  TRAINING_DATA_VIEWED = 'training_data_viewed',

  PAYMENT_SUCCESSFUL = 'payment_successful',

  CONNECTION_INITIATED = 'connection_initiated',
  CONNECTION_SUCCESSFUL = 'connection_successful',

  SEGMENT_ANALYSIS_VIEWED = 'segment_analysis_viewed',

  COPILOT_RESET_CHAT = 'copilot_reset_chat',
  COPILOT_SELECT_SEGMENT = 'copilot_select_segment',
  COPILOT_SELECT_LIST = 'copilot_select_list',
  COPILOT_SELECTED_MILESTONE = 'copilot_selected_milestone',
  COPILOT_ASK_QUESTION = 'copilot_ask_question',
  COPILOT_SHOW_SQL = 'copilot_show_sql',
  COPILOT_RERUN_SQL = 'copilot_rerun_sql',
  COPILOT_UPDATE_SQL = 'copilot_update_sql',
  COPILOT_RATE_ANSWER = 'copilot_rate_answer',
  COPILOT_LOAD_QUESTION = 'copilot_load_question',
  COPILOT_REMOVE_QUESTION = 'copilot_remove_question',
  COPILOT_TOGGLE_PRODUCTION = 'copilot_toggle_production',

}

type UserProperties = {
  email: string;
  first_name?: string;
  last_name?: string;
  org_name?: string;
}

export const useTrackEvents = () => {
  const posthog = usePostHog();

  const trackEvent = useCallback((event: EEvent, properties?: Record<string, unknown>) => {
    posthog.capture(event, properties);
  }, [posthog]);

  const identify = useCallback((userId: string, properties?: UserProperties) => {
    posthog.identify(userId, properties);
  }, [posthog]);

  const resetIdentity = useCallback(() => {
    posthog.reset();
  }, [posthog]);

  return {
    trackEvent,
    identify,
    resetIdentity,
  };
}

