import ComponentSpinner from "@src/components/ComponentSpinner";
import VerticalCenter from "@src/components/VerticalCenter";
import { Suspense } from "react";

export function SuspenseWithFallback({ children }: { children: React.ReactNode }) {
  return (
    <Suspense fallback={
      <VerticalCenter height={"100%"} width={"100%"} >
        <ComponentSpinner />
      </VerticalCenter>
    }>
      {children}
    </Suspense>
  )
}
