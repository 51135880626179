import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Typography, useTheme } from "@mui/material";
import { lighten } from '@mui/system/colorManipulator';
import { AppAlertsCtx } from '@src/components/AppAlerts';
import { AuthContext } from '@src/components/auth/auth.ctx';
import { ExternalLinkRenderer } from '@src/components/datagrid/ExternalLinkRenderer';
import { pricingUrl } from '@src/utils/constants';
import { useContext, useEffect, useState } from 'react';

export const AnnouncementRibbon = () => {
  const { addAlert } = useContext(AppAlertsCtx)
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(true)
  const { user } = useContext(AuthContext)
  // check number of days remaining in trial period based on created_at for 14 day trial
  const daysRemaining = user?.created_at ? Math.ceil((new Date(user.created_at).getTime() + 14 * 24 * 60 * 60 * 1000 - Date.now()) / (1000 * 60 * 60 * 24)) : 0;

  useEffect(() => {
    let timerId = null;
    if (daysRemaining <= 0 && user && !user.is_client) {
      addAlert({
        message: 'Your trial has expired. Please upgrade your plan to continue using Revamp.',
        type: 'error'
      });
      timerId = setTimeout(() => {
        window.location.href = pricingUrl;
      }, 2000);
    }
    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [addAlert, daysRemaining, user]);

  useEffect(() => {
    if (!user) return;
    if (!user.is_client || daysRemaining > 0) setIsOpen(true);
  }, [user, daysRemaining]);


  // If user is a client, don't show the ribbon (Paid user)
  if (!user || user?.is_client) return null

  if (!isOpen) return null

  if (daysRemaining <= 0) return null

  const daysRemainingText = daysRemaining === 1 ? '1 day' : `${daysRemaining} days`

  return (
    <Box
      width='100%'
      display='flex'
      alignItems='center'
      sx={{
        backgroundColor: lighten(theme.palette.warning.main, 0.9),
        color: lighten(theme.palette.warning.main, 0.2)
      }}
    >
      <Typography
        variant="caption"
        fontWeight='medium'
        paddingY={1}
        sx={{ margin: '0 auto' }}
      >
        <WarningIcon sx={{ fontSize: 16, verticalAlign: 'sub', marginRight: 0.5 }} />
        {daysRemainingText} of trial remaining. <ExternalLinkRenderer link={pricingUrl} sx={{ color: 'inherit' }}>Click to upgrade.</ExternalLinkRenderer>
      </Typography>
      <CloseIcon sx={{ fontSize: 16, verticalAlign: 'sub', cursor: 'pointer', marginRight: 1 }} color='btnAction' onClick={() => setIsOpen(false)} />
    </Box>
  )
}

