import { styled } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const Link = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.btnAction.main,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

