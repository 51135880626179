import { Box, CircularProgress, CircularProgressProps } from "@mui/material";

export default function ComponentSpinner(props: CircularProgressProps) {

  return (
    <Box height='100%' display='flex' justifyContent='center' alignItems='center'>
      <CircularProgress {...props} />
    </Box>
  )
}
