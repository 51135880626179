import { Link } from "@components/Link";
import { SxProps } from "@mui/system";

export const ExternalLinkRenderer = ({ link, children, sx }: { link: string | undefined, children?: React.ReactNode, sx?: SxProps }) => {
  if (!link) return null
  return (
    <Link to={link} target="_blank" color='btnAction.main' sx={sx}>
      {children ? children : 'Link'}
    </Link>
  )
}
