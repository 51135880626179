import logo from '@assets/co-pilot-logo.png';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Button, Card, CardContent, Grid, Stack, TextField, Typography, useTheme } from "@mui/material";
import { ExternalLinkRenderer } from '@src/components/datagrid/ExternalLinkRenderer';
import LoadingButton from '@src/components/LoadingButton';
import { privacyPolicyUrl } from '@src/utils/constants';
import { auth } from "@src/utils/spa.url";
import Image from "mui-image";
import { useState } from "react";
import { Link } from "react-router-dom";


type Props = {
  onSubmit: (username: string, password: string) => void
  onForgotPassword: () => void
  isLoading: boolean
}

export default function LoginForm({ onSubmit, onForgotPassword, isLoading }: Props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const theme = useTheme();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(email, password);
  }


  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={6}>
        <Grid item xs={12} container justifyContent="center">
          <Image src={logo} alt="Revamp Logo" width={70} duration={0} />
        </Grid>
        <Grid item xs={12}>
          <Card variant="elevation" elevation={8} sx={{ backgroundColor: 'background.default' }}>
            <CardContent>
              <Grid container spacing={3} padding={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" fontWeight={'medium'} marginY={2} textAlign={'center'}>Login</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="E-mail"
                    variant="outlined"
                    fullWidth
                    type="email"
                    required
                    InputLabelProps={{ required: false }}
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    required
                    InputLabelProps={{ required: false }}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />

                </Grid>
                <Grid item xs={12}>
                  <LoadingButton isLoading={isLoading} type="submit" size="large" color='btnAction' variant='contained' disabled={!email || !password} endIcon={<ArrowForwardOutlinedIcon />}>Log In</LoadingButton>
                  <Button
                    color="btnAction" variant="text" size='small' onClick={onForgotPassword}
                    sx={{ ml: 1, textTransform: 'none', fontSize: theme.typography.caption.fontSize, fontWeight: theme.typography.caption.fontWeight, color: theme.typography.caption.color }}
                  >
                    Forgot Password?
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction='row' justifyContent={'center'} alignItems={'baseline'}>
                    <Typography variant="caption" textAlign={'center'} component={'span'}>Don&apos;t have an account?</Typography>
                    <Button component={Link} to={auth.signup} color="btnAction" variant="text" size='small' sx={{ fontSize: theme.typography.caption.fontSize, fontWeight: theme.typography.caption.fontWeight }}>
                      Sign Up
                    </Button>
                  </Stack>
                  <Typography variant="caption" textAlign={'center'} component='p' color='text.secondary' marginTop={2}>
                    By continuing, you agree to our <ExternalLinkRenderer link={privacyPolicyUrl}>Privacy Policy.</ExternalLinkRenderer>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  )
}
