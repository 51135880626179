export const MODE = import.meta.env.MODE;

export const API_BASE_URL = '/api/v0';

// Sentry
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT;

// Posthog
export const POSTHOG_KEY = import.meta.env.VITE_POSTHOG_KEY;

export const enum Environment {
  LOCAL = 'local',
  UAT = 'uat',
  PRODUCTION = 'production',
}
