export const helpEmail = 'support@rev-amp.ai';

export const websiteUrl = 'https://www.rev-amp.ai';

export const privacyPolicyUrl = 'https://www.rev-amp.ai/privacy/';

export const paymentUrl = 'https://buy.stripe.com/dR6bKv8VU69wds43cf';

export const pricingUrl = 'https://www.rev-amp.ai/pricing/';

export const optionalURLPattern = '^(https?://)?(www\\.)?[a-zA-Z0-9-]+\\.[a-zA-Z]{2,}(/[a-zA-Z0-9-._?,&/\\+&%$#=~]*)?$'
