import { createContext } from "react";


// Although this is not used, it is a good way to share trivial state between components on App level
export type TAppContext = Record<string, never>

const defaultAppContext: TAppContext = {

}

export const AppContext = createContext<TAppContext>(defaultAppContext);


export const AppContextProvider = AppContext.Provider
