import * as React from 'react';


export default function ErrorScreenImage(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={320}
      height={238}
      fill='none'
      {...props}
    >
      <path fill='#E6E6E6' d='M255.928 44.495h-182.2v124.078h182.2V44.495Z' />
      <path
        fill='#CCC'
        d='M255.928 70.328h-182.2v98.245h182.2V70.328Z'
        opacity={0.44}
      />
      <path
        fill='#000'
        d='m248.968 142.455-13.88 19.489h13.064v6.628h7.776l-.488-23.771-6.472-2.346Z'
        opacity={0.08}
      />
      <path
        fill='#000'
        d='M76 80.86s3.776-2.513 5.544 6.714c1.768 9.227-3.416 17.064 9 18.96 12.416 1.896 20.544-3.286 23.408 11.439 2.864 14.726 18.992 21.386 13.48 34.697-5.512 13.312-10.112 15.903-10.112 15.903H73.728S73.4 86.105 76 80.859Z'
        opacity={0.04}
      />
      <path
        fill='#E6E6E6'
        d='M80.409 219.638c32.615 0 59.056-1.69 59.056-3.776 0-2.085-26.441-3.776-59.056-3.776-32.616 0-59.057 1.691-59.057 3.776 0 2.086 26.44 3.776 59.056 3.776ZM254.76 218.358c16.308 0 29.528-1.316 29.528-2.939s-13.22-2.939-29.528-2.939c-16.307 0-29.528 1.316-29.528 2.939s13.221 2.939 29.528 2.939Z'
        opacity={0.8}
      />
      <path
        fill='#131416'
        d='M88.423 59.34c1.935 0 3.504-1.55 3.504-3.46 0-1.912-1.569-3.461-3.504-3.461s-3.504 1.55-3.504 3.46c0 1.911 1.569 3.46 3.504 3.46Z'
      />
      <path
        fill='#C5BFCB'
        d='M98.848 59.34c1.935 0 3.504-1.55 3.504-3.46 0-1.912-1.569-3.461-3.504-3.461-1.936 0-3.504 1.55-3.504 3.46 0 1.911 1.569 3.46 3.504 3.46Z'
      />
      <path
        fill='#D0D0D0'
        d='M108.673 59.34c1.935 0 3.504-1.55 3.504-3.46 0-1.912-1.569-3.461-3.504-3.461s-3.504 1.55-3.504 3.46c0 1.911 1.569 3.46 3.504 3.46Z'
      />
      <path
        fill='#A195AB'
        d='M53.647 209.399s-.984 2.702-3.456 2.908c-2.472.205-3.112 2.591-.896 3.207 2.216.616 7.952-2.196 7.952-2.196l-.168-3.516-3.432-.403ZM87.569 209.399s.976 2.702 3.448 2.908c2.472.205 3.112 2.591.896 3.207-2.216.616-7.944-2.196-7.944-2.196l.16-3.516 3.44-.403Z'
      />
      <path
        fill='#fff'
        d='M123.312 108.73a1.968 1.968 0 0 1-.435-2.155c.101-.24.249-.458.435-.641l11.928-11.771a2.015 2.015 0 0 1 2.747.076 1.96 1.96 0 0 1 .077 2.712l-11.928 11.779a2.013 2.013 0 0 1-2.824 0Z'
      />
      <path
        fill='#fff'
        d='m135.433 108.919-11.928-11.78a1.963 1.963 0 0 1-.458-2.168c.1-.243.249-.463.437-.648a2.003 2.003 0 0 1 1.433-.577 2.017 2.017 0 0 1 1.42.605l11.92 11.771a1.946 1.946 0 0 1 .465 2.168 1.994 1.994 0 0 1-1.867 1.229 2.023 2.023 0 0 1-1.422-.6ZM189.457 108.729a1.958 1.958 0 0 1 0-2.796l11.928-11.771a2.015 2.015 0 0 1 3.349.586 1.94 1.94 0 0 1-.044 1.562 1.98 1.98 0 0 1-.481.64l-11.92 11.779a2.03 2.03 0 0 1-2.832 0Z'
      />
      <path
        fill='#fff'
        d='m201.601 108.919-11.928-11.78a1.963 1.963 0 0 1-.459-2.168 1.976 1.976 0 0 1 1.096-1.079 2.023 2.023 0 0 1 2.195.459l11.92 11.771a1.946 1.946 0 0 1 .465 2.168 1.994 1.994 0 0 1-1.867 1.229 2.023 2.023 0 0 1-1.422-.6ZM147.951 142.454c-.501 0-.984-.188-1.352-.524a1.962 1.962 0 0 1-.632-1.293c-.56-6.723 2.024-19.955 15.776-21.883 4.296-.592 7.888.38 10.672 2.908 6.536 5.932 5.888 18.407 5.864 18.96a1.966 1.966 0 0 1-.665 1.364 2.004 2.004 0 0 1-1.447.5 2.025 2.025 0 0 1-1.381-.657 1.971 1.971 0 0 1-.507-1.429c0-.11.568-11.139-4.576-15.8-1.888-1.706-4.304-2.322-7.4-1.896-13.504 1.825-12.408 16.97-12.352 17.625a1.96 1.96 0 0 1-.47 1.437 2.01 2.01 0 0 1-1.362.688h-.168Z'
      />
      <path
        fill='#A195AB'
        d='M90.728 101.453s17.6 3.516 16.152 19.75c-1.448 16.235 14.552 25.106 4.744 34.476-9.808 9.369-22.88 4.1-22.88 4.1l1.984-58.326Z'
      />
      <path
        fill='#fff'
        d='M90.728 101.453s17.6 3.516 16.152 19.75c-1.448 16.235 14.552 25.106 4.744 34.476-9.808 9.369-22.88 4.1-22.88 4.1l1.984-58.326Z'
        opacity={0.39}
      />
      <path
        fill='#F4A28C'
        d='M76.16 85.386a22.525 22.525 0 0 1 1.44 5.893 1.812 1.812 0 0 1-.502 1.318 1.854 1.854 0 0 1-1.299.578 4.123 4.123 0 0 1-2.275-.434c-.7-.359-1.28-.91-1.669-1.588l-1.976-2.741a3.244 3.244 0 0 1 .4-3.769c1.584-1.983 5.16-1.366 5.88.743Z'
      />
      <path
        fill='#F4A28C'
        d='m70.792 89.099.152 11.952 6.664-.553-2.792-8.476-4.024-2.923Z'
      />
      <path
        fill='#131416'
        d='M70.256 91.255a.332.332 0 0 0 .344.245 2.483 2.483 0 0 0 1.478-.513c.423-.325.727-.777.866-1.289a2.997 2.997 0 0 0-.007-1.72 3.03 3.03 0 0 0-.953-1.44 14.895 14.895 0 0 0 3.344-.71c.366-.121.709-.3 1.016-.53l.12-2.891s.672-3.05-1.064-3.16c-1.736-.11-1.952 1.864-3.592.948-1.64-.917-2.08-1.304-3.2-.23-1.12 1.075.68 1.667-1.472 2.276-2.152.608-1.952 1.493-1.688 2.465.138.324.372.6.672.79a11.206 11.206 0 0 1 4.136 5.759Z'
      />
      <path
        fill='#F4A28C'
        d='M73.296 89.485s-.432-1.343-1.424-.94-.384 2.37 1.064 2.117l.36-1.177ZM76.904 87.574l1.384 1.059a.586.586 0 0 1 .227.513.58.58 0 0 1-.315.466l-1.28.648-.016-2.686Z'
      />
      <path
        fill='#CE8172'
        d='M75.2 93.159a5.732 5.732 0 0 1-2.688-.853s.416 2.37 3.528 3.341l-.84-2.488Z'
        opacity={0.31}
      />
      <path
        fill='#A195AB'
        d='M83.528 99.898a86.17 86.17 0 0 0-31.952 6.746 12.332 12.332 0 0 0-4.11 2.804 12.135 12.135 0 0 0-2.645 4.186 12.019 12.019 0 0 0 .54 9.648l17.383 34.246 32.224-1.888s5.872-19.244 4.696-41.009a15.561 15.561 0 0 0-5.06-10.579 15.952 15.952 0 0 0-11.076-4.154Z'
      />
      <path
        fill='#131416'
        d='m62.744 157.528-9.6 52.361h4.8l12.072-28.44c4.275-10.069 11.813-18.451 21.432-23.834l3.536-1.983-32.24 1.896Z'
      />
      <path
        fill='#000'
        d='M60.08 111.194a16.921 16.921 0 0 1 5.123 6.935 16.713 16.713 0 0 1 1.013 8.526c-1.472 9.914-3.016 17.625 6.264 15.31 9.28-2.315 14.264-23.566 14.264-23.566l10.8.261-1.864 23.7-6.264.426c-1.482.1-2.89.679-4.006 1.647a6.663 6.663 0 0 0-2.17 3.71l-1.688 8.271-18.808 1.098-2.664-3.95-11.736-12.514-1.464-20.018 13.2-9.836Z'
        opacity={0.08}
      />
      <path
        fill='#F4F4F4'
        d='m72.875 138.609 20.928 1.447 2.006-28.306-20.928-1.447-2.006 28.306Z'
      />
      <path
        fill='#131416'
        d='M94.969 155.641 90 209.89h-6.4s1.496-40.819-10.056-49.509l21.424-4.74Z'
      />
      <path
        fill='#A195AB'
        d='M60.8 114.26a7.688 7.688 0 0 0-1.555-4.423 7.846 7.846 0 0 0-3.851-2.731 7.945 7.945 0 0 0-4.741-.042 7.845 7.845 0 0 0-3.9 2.661c-3.697 4.661-7.105 12.435-6.825 25.201.6 27.255 16.8 34.562 41.8 4.74l-4.928-3.753s-9.84 8.248-18.856 8.367c-6.744.094 3.312-17.017 2.856-30.02Z'
      />
      <path
        fill='#fff'
        d='M60.8 114.26a7.688 7.688 0 0 0-1.555-4.423 7.846 7.846 0 0 0-3.851-2.731 7.945 7.945 0 0 0-4.741-.042 7.845 7.845 0 0 0-3.9 2.661c-3.697 4.661-7.105 12.435-6.825 25.201.6 27.255 16.8 34.562 41.8 4.74l-4.928-3.753s-9.84 8.248-18.856 8.367c-6.744.094 3.312-17.017 2.856-30.02Z'
        opacity={0.39}
      />
      <path
        fill='#000'
        d='M94.785 125.123s-7.2 13.059-21.6 9.369l-.312 4.575 20.88 1.398 1.032-15.342Z'
        opacity={0.08}
      />
      <path
        fill='#F4A28C'
        d='M77.71 136.601s2.945-5.135 6.545-3.776c3.6 1.358-2.528 6.841-2.528 6.841l-4.016-3.065Z'
      />
      <path
        fill='#A195AB'
        d='M110.145 207.093s-6.816-1.841-8.296-8.098c0 0 10.552-2.109 10.856 8.651l-2.56-.553Z'
        opacity={0.58}
      />
      <path
        fill='#A195AB'
        d='M110.975 206.422s-4.76-7.426-.568-14.378c0 0 8 5.04 4.456 14.394l-3.888-.016Z'
        opacity={0.73}
      />
      <path
        fill='#A195AB'
        d='M112.201 206.43s2.52-7.845 10.12-9.33c0 0 1.424 5.095-4.92 9.353l-5.2-.023Z'
      />
      <path
        fill='#131416'
        d='m107.264 206.255 1.376 9.33 8.704.031 1.28-9.314-11.36-.047Z'
      />
      <path fill='#E6E6E6' d='M258.024 151.793h-5.176v51.445h5.176v-51.445Z' />
      <path
        fill='#94899D'
        d='m255.928 124.704-20.84 31.529h41.688l-20.848-31.529Z'
      />
      <path fill='#131416' d='M263.263 198.83h-15.112v16.795h15.112V198.83Z' />
      <path
        fill='#fff'
        d='M257.239 136.428h-2.96v9.48h2.96v-9.48ZM257.239 147.756h-2.96v2.923h2.96v-2.923Z'
      />
    </svg>
  );
}
